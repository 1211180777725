var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"green","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_c('span',{staticStyle:{"color":"#FFF"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"#FFF"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-app',{attrs:{"id":"inspire"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs10":"","align-self-center":"","text-center":"","pt-2":""}},[_c('v-layout',{attrs:{"wrap":"","pb-6":""}},[_c('v-flex',{attrs:{"text-center":"","align-self-center":"","pt-md-12":""}},[_c('span',{staticClass:"popregular xlarge"},[_vm._v("SELECT YOUR PAYMENT METHOD")])])],1),_c('v-card',{attrs:{"flat":"","height":"60vh"}},[(_vm.$route.query.country == 'India')?_c('v-layout',{attrs:{"wrap":"","justify-center":"","align-center":"","pt-10":""}},[_c('v-flex',{attrs:{"xs12":"","md3":"","text-left":""}},[_c('v-checkbox',{staticClass:"slotText",attrs:{"hide-details":"","ripple":false,"name":"optcheck1","value":"phonepe"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('v-img',{attrs:{"src":require("../assets/Images/PhonePe.png"),"width":_vm.$vuetify.breakpoint.name == 'xs'
                        ? '25vw'
                        : _vm.$vuetify.breakpoint.name == 'sm'
                        ? '18vw'
                        : _vm.$vuetify.breakpoint.name == 'md'
                        ? '15vw'
                        : _vm.$vuetify.breakpoint.name == 'lg'
                        ? '15vw'
                        : '14vw'}})],1)]},proxy:true}],null,false,1207772782),model:{value:(_vm.paytype),callback:function ($$v) {_vm.paytype=$$v},expression:"paytype"}})],1)],1):_vm._e(),_c('v-layout',{attrs:{"justify-center":"","pt-15":""}},[_c('v-flex',{attrs:{"text-center":"","xs10":"","sm3":"","md2":""}},[_c('v-btn',{attrs:{"ripple":false,"depressed":"","block":"","rounded":"","color":"green"},on:{"click":_vm.validateInput}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"13px","color":"#fff","text-transform":"none"}},[_vm._v(" SUBMIT ")])])],1)],1)],1)],1)],1),_c('span',[_vm._v(_vm._s(_vm.url1))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }